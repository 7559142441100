// REACT
import React from "react"
// MUI
import { Link, Typography } from "@material-ui/core"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"

// COMPONENT FUNCTION
const TideCalendarsPage = () => {
  // IMAGES AND PDF
  const data = useStaticQuery(graphql`
    query {
      tideCalendar: file(relativePath: { eq: "tide-calendar.png" }) {
        ...BsmFluidImage
      }
      tideCalendarPDF: file(
        relativePath: { eq: "wellfleet-tide-calendar-2024.pdf" }
      ) {
        publicURL
        name
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Tide Calendars">
      <PageTitle title="TIDE CALENDARS" />

      <ImageBlock>
        <Link href={data.tideCalendarPDF.publicURL}>
          <ImageWithCaption
            fluid={data.tideCalendar.childImageSharp.fluid}
            caption="Bay Sails Marine tide calendar"
            displayCaption
            maxWidth={360}
          />
        </Link>
      </ImageBlock>

      <Typography variant="h6" component="h2" gutterBottom>
        Not for Navigation
      </Typography>
      <Typography paragraph>
        All tide information is distributed in the hope that it will be useful.
        It should not be used if anyone or anything could come to harm as a
        result of an incorrect tide prediction.{" "}
        <Link href="https://tidesandcurrents.noaa.gov/">NOAA</Link> can provide
        you with certified tide predictions if that is what you need.
        Predictions do not incorporate the effects of current weather conditions
        or changing climate patterns.
      </Typography>

      <Typography
        variant="h4"
        component="p"
        align="center"
        paragraph
        style={{ marginTop: "4rem" }}
      >
        <Link href={data.tideCalendarPDF.publicURL}>
          Download Wellfleet Tide Calendar
        </Link>
      </Typography>
    </Layout>
  )
}

export default TideCalendarsPage
